import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 商品详情

export function getNewProductList(data){
  return request({
      url:`${baseURL.api}/api/product/newUserProduct`,
      method: "post",
      data
  });
}
//查询商品
export function getSeachProductList(data){
  return request({
      url:`${baseURL.api}/api/product/list`,
      method: "post",
      data
  });
}