<template>
  <div class="newProduct">
    <div class="newProduct-list">
      <div
        class="newProduct-list-item"
        v-for="item in data.products"
        :key="item.id"
        @click="getNewProductDetial(item)"
      >
        <div class="newProduct-list-img">
          <img :src="getProductImageUrl(item.picturePath, 400)" @error="handleImageError" />
        </div>
        <div class="newProduct-info">
          <div class="newProduct-name">
            {{ item.brandName ? item.brandName : "" }} {{ item.name }}
          </div>
          <div v-if="data.isAuth">
            <div
              class="newProduct-price"
              v-if="
                parseInt(item.price) !== 1000000 &&
                  (item.price > 0 || item.discountPrice > 0)
              "
            >
              {{
                formatNumber(
                  item.price !== item.discountPrice && item.discountPrice > 0
                    ? item.discountPrice
                    : item.price
                )
              }}
            </div>
            <div
              class="newProduct-Originalprice"
              v-if="
                item.price !== item.discountPrice &&
                  parseInt(item.price) !== 1000000 && item.discountPrice > 0
              "
            >
              {{ formatNumber(item.price) }}
            </div>
            <div
              class="newProduct-price"
              v-if="parseInt(item.price) === 1000000"
            >
              {{ $t("詢價") }}
            </div>
          </div>
          <div v-else class="newProduct-price">
            {{ data.text }}
          </div>
        </div>
      </div>
    </div>
    <van-loading v-if="isMore && !isEmpty" size="13px" class="loading"
      >{{ $t("加载中") }}...</van-loading
    >
    <div v-if="isEmpty" class="more">{{ $t("沒有更多啦") }}~</div>
  </div>
</template>

<script>
import { getNewProductList, getSeachProductList } from "@/api/newProduct";
import Mixins from "@/utils/mixins";
import i18n from "@/i18n";
import baseURL from "@/utils/config";
export default {
  mixins: [Mixins],
  data() {
    return {
      data: {},
      formPage: {
        pageIndex: 1,
        pageSize: 10,
        isInit: true,
        tagID: "8"
      },
      productPage: {
        pageIndex: 1,
        title: "",
        searchType: "",
        brandId: "",
        pageSize: 10
      },
      isMore: false,
      finished: false,
      imgUrl: baseURL.imgUrl,
      isEmpty: false,
      defaultImg: require('@/assets/image/e-Rith.png') 
    };
  },
  async created() {
    if (this.$route.query.langEnv == 2) {
      i18n.global.locale.value = "en";
    } else if (this.$route.query.langEnv == 3) {
      i18n.global.locale.value = "zh";
    }
    let id = this.$route.params.tagID;
    this.formPage.tagID = id;
    // switch (id) {
    //   case "6":
    //     document.title = this.$t("特價商品");
    //     break;
    //   case "7":
    //     document.title = this.$t("熱賣商品");
    //     break;
    //   case "8":
    //     document.title = this.$t("新品專區");
    //     break;
    //   case "12":
    //     document.title = this.$t("近效期折扣");
    //     break;
    // }
    window.addEventListener("scroll", this.throttle(this.handleScroll,1000));

    await this.getList();
    if(this.$route.params.brandId){
        document.title = this.data?.products[0]?.brandName || '';

    }else  if(this.$route.params.categoryId){
      document.title = this.data?.parentCategories?.find(item=>item.id===this.$route.params.categoryId)?.name || '';
    }
  },
  methods: {
    async handleScroll(event) {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight;

      // 判断是否触底
      const isBottomReached = () => {
        const bottomOffset = 20; // 距离底部多少像素时认为触底
        return (
          documentHeight - (scrollTop + windowHeight) <= bottomOffset &&
          hasVerticalScrollbar
        );
      };

      if (isBottomReached()) {
        this.isMore = true;
        if (this.$route.params.brandId || this.$route.params.categoryId) {
          this.productPage.pageIndex += 1;
        } else {
          this.formPage.pageIndex += 1;
        }

        this.formPage.isInit = false;
        await this.getList();
      }
    },
    async onLoad() {
      this.formPage.pageIndex += 1;
      await this.getList();
    },
    getNewProductDetial (item) {
      this.$router.push(`/product/${item.id}`)
      return
      var _this = this;
      var func1 = function() {
        mmm.goToDetail(item.id);
      };
      var func2 = function() {
        window.webkit.messageHandlers.goToDetail.postMessage(item.id);
      };
      var func3 = function() {
        return;
        // _this.$router.push({ name: "Product", params: { id: id } });
      };
      this.excFunc(func1, func2, func3);
    },
    async getList() {
      if (this.isEmpty) {
        return;
      }
      if (this.$route.params.brandId) {
        this.productPage.brandId = this.$route.params.brandId;
        this.productPage.searchType = "brandId";
        this.productPage.categoryId = undefined;
      } else if (this.$route.params.categoryId) {
        this.productPage.categoryId = this.$route.params.categoryId;
        this.productPage.searchType = "categoryId";
        this.productPage.brandId = undefined;
      }
      const res =
        this.$route.params.brandId || this.$route.params.categoryId
          ? await getSeachProductList(
              this.productPage,
              this.$route.query.langEnv
            )
          : await getNewProductList(this.formPage, this.$route.query.langEnv);
      if (this.formPage.pageIndex > 1 || this.productPage.pageIndex > 1) {
        if (res.data.products && res.data.products?.length === 0) {
          this.isMore = false;
        } else {
          if (res.data?.products) {
            this.data.products = [...this.data.products, ...res.data?.products];
          }
        }
      } else {
        this.data = res.data;
      }
      if (res.data?.products?.length === 0) {
        this.isMore = false;
        this.isEmpty = true;
      }
    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
  }
};
</script>

<style lang="scss">
.newProduct-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-between;
  .newProduct-list-item {
    width: 49.596%;
    background-color: #fff;
    padding: 2px;
    border-radius: 12px;
    border-bottom: 3px solid #f3f4f8;
    overflow: hidden;
    img {
      object-fit: contain;
      min-height: 180px;
    }
    .newProduct-info {
      padding: 8px;
      .newProduct-name {
        min-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 设置为2行 */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .newProduct-Originalprice {
        text-decoration: line-through;
        font-size: 12px;
        color: #999;
      }
      .newProduct-price {
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 16px;
        color: #f82207;
        line-height: 32px;
      }
    }
  }
}
.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}
.loading {
  text-align: center;
  padding-bottom: 10px;
}
</style>
